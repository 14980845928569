import React from 'react';
import Layout from '../../../../components/layout';
import '../../../../styles/sass/global.scss';
import SEO from '../../../../components/seo';
import { Grid, Typography } from '@material-ui/core';
import SectionContainerLayoutWithFilter from '../../../../components/section-container-layout-with-filter';
import CardImageTitleDescription from '../../../../components/card-image-title-description/card-image-title-description';

export default function Technology() {
  const TECHNOLOGY = [
    {
      image: 'technology_humpback_whales',
      title: 'HUMPBACK WHALES AND WIND TURBINES',
      body:
        'Humpback whales use their fins at different angles to increase their lift. Scientists discovered that a higher angle proficiency was beneficial for the whale to manoeuvre in tight circles. It led experts to create serrated-edge wind turbines that proved to be more efficient and quieter than the typical smooth blades.',
    },
    {
      image: 'technology_box_fish',
      title: 'BOX FISH AND THE BIONIC CAR',
      body:
        'Boxfish were discovered to have great structural strength and low mass resulting in low flow resistance and an extremely coefficient drag – this inspired the bionic car, which has reduced drag, greater rigidity, lower weight and fuel consumption than traditional cars.',
    },
    {
      image: 'technology_kingfisher',
      title: 'KINGFISHER AND THE SHINKANSEN',
      body:
        'The design of Japanese bullet trains are inspired by the Kingfisher, renowned for travelling between the mediums of air & water, with very little splash. The tip of the train is formatted with a long beak-shaped nose which reduces noise, electricity usage and increases speed.',
    },
    {
      image: 'technology_burrs',
      title: 'Burrs and Velcro',
      body:
        'Velcro was invented after scientists noticed how easy it was for burrs to stick to dog’s hair due to the simple design of tiny hooks at the end of the burrs spines. This replicated synthetically enabled experts to create velcro.',
    },
    {
      image: 'technology_water collation',
      title: 'Water Collation and Desert Beetles',
      body:
        'The African Namibia Desert Beetle collects water by condensing fog into water droplets in the bumps on its shell. This inspired researchers to replicate this with glass and plastic – enabling them to collect minute amounts of water in addition to providing the groundwork for other applications',
    },
    {
      image: 'technology_shark_skin',
      title: 'Shark Skin and Fuel Saving',
      body:
        'Examining the microscopic patterns of ventricles on the surface of sharks’ skin allowed scientists to replicate the process to create a ‘riblets’ lm which reduces drag and deters microorganisms (such as algae) attaching to the surface – an invention extremely useful to marine vessels and which had added environmental benets.',
    },
    {
      image: 'technology_birds',
      title: 'Birds and Flight',
      body:
        'Biomimicry is perhaps most famous in forms of human ight, with experts studying how birds soar inspiring how man could potentially y. Eventually, the Wright brothers To successfully invent, build and y the world’s rst successful airplane.',
    },
  ];

  return (
    <Layout>
      <SEO lang='en' title='Technology' />
      <Grid container direction='column' spacing={10} className='griiid'>
        <Grid item>
          <SectionContainerLayoutWithFilter
            title='#TECHNOLOGY'
            isViewAll
            viewAllText='Back To GMIS2019'
            baseLink='/summits/2019'>
            <Grid container>
              <Grid item xs={12} lg={12}>
                <Typography variant='body1' color='textSecondary'>
                  #GMIS2019 highlighted the role of nature-inspired technologies, its impact on manufacturing, its
                  benefits to communities, and its role in advancing the Sustainable Development Goals (SDGs) of the
                  United Nations, as depicted by the UN’s 2030 Agenda for Sustainable Development.
                </Typography>
              </Grid>
            </Grid>
          </SectionContainerLayoutWithFilter>
        </Grid>
        <Grid item>
          <Grid container spacing={3}>
            {TECHNOLOGY.map((tech, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <CardImageTitleDescription
                  disabelRippleEffect
                  image={tech.image}
                  title={`${tech.title}`}
                  body={tech.body}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
}
